<template>
    <div class="main">
<!--        <div style="margin:10px;">-->
<!--&lt;!&ndash;          <div @click="onaddxx">添加</div>&ndash;&gt;-->
<!--          <van-button type="primary" style="width:100%;" @click="onaddxx">新的体检</van-button>-->
<!--        </div>-->
        <div  v-if="!errstate">    
            <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh">
                <van-list
                    v-model:loading="state.loading"
                    :finished="state.finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                <van-cell center v-for="(item,index) in objData.cxArr" :key="index" >
                    <div class="qjListItem">
                        <div class="divTitle">
                          <div style="font-size:1.2em; font-weight:bold; ">{{item.czsj}}</div>
                          <div>{{item.email}}</div>
                        </div>
                        <div style="width:50px; text-align: right; display:flex;  display: -webkit-flex; align-items:center; ">
                            <span v-if="item.pext === 1" class="lab_scz" >生成中</span>
                            <span v-if="item.pext === 0" class="czspn" @click.stop="safk_ck(item)">查看</span>
                        </div>
                    </div>
                </van-cell>
             </van-list>
            </van-pull-refresh>
        </div>
        <van-empty image="error" v-if="errstate" :description=description />
    </div>

  <van-image-preview
      v-model:show="state.show"
      :images="state.images"
      @change="onChange"
      :closeable="state.closeable"
  >
  </van-image-preview>
  <!-- 遮罩loading -->
  <van-overlay :show="showload" @click="showload = false" >
    <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
  </van-overlay>


  <div class="float_btn_outer" id="float_btn_div"
       @mousedown="float_btn_down" @touchstart="float_btn_down"
       @mousemove="float_btn_move" @touchmove="float_btn_move"
       @mouseup="float_btn_end" @touchend="float_btn_end"
       @click="onaddxx"
  >
    <div class="float_btn_inner"> 体检 </div>
  </div>

</template>

<script>
import { ref,reactive,getCurrentInstance } from 'vue';
import {Dialog, Button, Toast} from 'vant';
import Request from '../../api/swtj'
import config from "@/config/"
import {getLocalStorage} from '../../utils/local-storage'

export default {
    name: "commodity",
    
    setup(){
      // let t_auth = getLocalStorage(config.request.localKey);
      //   console.log(t_auth)
      //   let t_auth_infos = t_auth[config.request.localKey];
      //   console.log(t_auth)
      //   if (t_auth_infos === null || t_auth_infos === "null"){
      //      Toast("请登录1111111111111111111");
      //      return
      //   }

        const { proxy } = getCurrentInstance();
        const page = ref("1");
        const pageSize = ref(config.request.pageSize);
        const errstate = ref(false);
        const description = ref("");
        const objData = reactive({
            cxArr:[]
        });

        
        const state = reactive({
            list: [],
            loading: false,
            finished: false,
            refreshing: false,

            show: false,
            index: 0,
            images:[],
            closeable: true,
        });
        const onChange = (index) => {
          state.index = index;
        };

        const searchMethod = (params)=> {
            Request.safk_cx(params).then(res=>{
                console.log('res=>',res);    
                // objData.cxArr = res.rows.datas;
                if (res.returnCode === "00") {  
                    errstate.value = false;
                    if (state.refreshing) {
                        objData.cxArr = [];
                        state.refreshing = false;
                        page.value = "1";
                    }
                    objData.cxArr.push(...res.rows.datas);  
                    state.loading = false;
                    page.value = Number(page.value) + 1;
                    if (objData.cxArr.length >= res.rows.total) {
                        state.finished = true;
                        console.log("11111111111");
                    }
                }else{
                    errstate.value = true;
                    description.value = res.returnMessage;
                }
            }).catch(err=>{
                console.log('err=>',err);   
            })
        };
        const onSearch = ()=> {
            page.value = "1";
            objData.cxArr = [];
            searchMethod({
                page:'1',
                pageSize: pageSize.value
            });
            
        };
        const onLoad = () => {
            searchMethod({
                page: page.value,
                pageSize: pageSize.value
            });
        };
        const onRefresh = () => {
                // 清空列表数据
                state.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                state.loading = true;
                searchMethod({
                    page: "1",
                    pageSize: pageSize.value
                });
            };
        const onaddxx = ()=> {
            proxy.$root.$router.push({path:"/swtj_add"});
            sessionStorage.setItem('isAdd','0');
        };
        const safk_ck = (row)=> {
            showload.value = true;
            Request.safk_yl({ uuid:row.uuid }) .then(res=>{
              showload.value = false;
              if (res.returnCode === "00"){
                state.images=[];
                for(var i=0;i<res.rows.length;i++){
                  res.rows[i] = 'data:image/png;base64,'+res.rows[i];
                }
                state.images=res.rows;
                state.show=true;
              }else{
                Toast(res.returnMessage);
              }
            }).catch(err=>{
                console.log('err=>',err);
            })
        }
         const cellClick = (item)=>{
            item.rate = item.rate*100;
            item.rate=item.rate+'%';
            proxy.$root.$router.push({name: "compAdd", params:item});
            sessionStorage.setItem('isAdd','1');
        }

        const showload = ref(false);

        const float_btn_item = ref({
          flags: false,
          position: { x: 0, y: 0 },
          nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
        });
        const float_btn_down = (ev)=>{
          float_btn_item.value.flags = true;
          var touch;
          if(ev.touches){
            touch = ev.touches[0];
          }else {
            touch = ev;
          }
          float_btn_item.value.position.x = touch.clientX;
          float_btn_item.value.position.y = touch.clientY;
          let t_doc = document.querySelector("#float_btn_div");
          float_btn_item.value.dx = t_doc.offsetLeft;
          float_btn_item.value.dy = t_doc.offsetTop;
        }
        const float_btn_move = (ev)=>{
          if(float_btn_item.value.flags){
            var touch ;
            if(ev.touches){
              touch = ev.touches[0];
            }else {
              touch = ev;
            }
            float_btn_item.value.nx = touch.clientX - float_btn_item.value.position.x;
            float_btn_item.value.ny = touch.clientY - float_btn_item.value.position.y;
            float_btn_item.value.xPum = float_btn_item.value.dx+float_btn_item.value.nx;
            float_btn_item.value.yPum = float_btn_item.value.dy+float_btn_item.value.ny;
            let t_doc = document.querySelector("#float_btn_div");
            t_doc.style.left = float_btn_item.value.xPum+"px";
            t_doc.style.top = float_btn_item.value.yPum +"px";
            //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
            document.addEventListener("touchmove",function(){
              ev.preventDefault();
            },false);
          }
        }
        const float_btn_end = (ev)=>{
          float_btn_item.value.flags = false;
        }

        

        return{
            onSearch,
            onaddxx,
            objData,
            safk_ck,
            state,
            onRefresh,
            onLoad,
            errstate,
            description,
            page,
            pageSize,
            cellClick,
            showload,
            onChange,
            float_btn_item,
            float_btn_down,
            float_btn_move,
            float_btn_end,
            // t_auth
        };
        
    }
}
</script>

<style scoped>
    .main {
        min-height: 100vh;
        position: relative;
    }
    .qjListItem{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .divTitle{
        flex: 1;
        text-align: left;
    }
    .divbold{
        font-weight: bold;
    }
    .czspn{
        color:#1989fa;
    }
    .lab_scz{
      color:#80edb5;
    }
    .float_btn_outer {
      height: 4.5rem;
      width: 4.5rem;
      /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
      z-index: 999;
      position: fixed;
      top: 20rem;
      right: 1rem;
      border-radius: 0.8rem;
    }
    .float_btn_inner {
      height: 2.7rem;
      width: 2.7rem;
      border: 0.3rem solid #1989FA;
      margin: 0.65rem auto;
      color: white;
      font-size: 1.0rem;
      line-height: 2.7rem;
      text-align: center;
      border-radius: 100%;
      background-color: #1989FA;
    }
</style>
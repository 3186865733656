import request from '../utils/request'

export default {
    safk_cx(data) {
        return request({
            url: 'api/sbxx/safk_cx_page',
            method:'post',
            data
        })
    },
    safk_nsrxx(data) {
        return request({
            url: 'api/sbxx/dsf_nsrxx',
            method:'post',
            data
        })
    },
    safk_fxjc(data) {
        return request({
            url: 'api/sbxx/safk_fxjc',
            method:'post',
            data
        })
    },
    safk_yl(data) {
        return request({
            url: 'api/sbxx/safk_yl',
            method:'post',
            data
        })
    },
    safk_zq(data) {
        return request({
            url: 'api/sbxx/safk_zq',
            method:'post',
            data
        })
    }

}